<template>
    <div class="page-content-inner">
        <h2>{{ pageTitle }}</h2>
        <div class="uk-grid">
            <div class="uk-width-2-5@m">

                <div class="uk-card-default rounded text-center p-4">
                    <div class="user-profile-photo  m-auto">
                        <Avatar :altName="userName" :avatarURL="userAvatar" />
                        <a href="#" @click.prevent="deleteAvatar" class="delete" v-if="userAvatar" >
                            <i class="icon-feather-trash-2" ></i>
                        </a>
                    </div>
                    <h4 class="mb-2 mt-3">{{ userName }} </h4>
                    <p class="m-0"> Member since {{ memberSince }} </p>
                </div>

                <div class="uk-card-default rounded mt-5">
                    <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                        <h5 class="mb-0">{{ pageContent('upload_profile_picture') }}</h5>
                    </div>
                    <hr class="m-0">
                    <div class="p-3">
                        <form method="post" id="update_profile_picture" action="#" enctype="multipart/form-data" @submit.prevent="uploadDP">
                            <div class="uk-grid-small uk-flex-middle" uk-grid>
                                <div class="uk-form-group" id="dp_block">
                                    <label class="uk-form-label">{{ pageContent('upload_image') }}</label>
                                    <div class="uk-position-relative w-100">                            
                                        <input class="uk-input" name="dp" ref="file" type="file" required accept="image/jpeg, image/png">
                                    </div>
                                    <p class="error-msg d-none"></p>
                                </div>
                            </div>
                            <div class="uk-flex-middle uk-grid-small uk-grid" uk-grid="">  
                                <div class="uk-width-auto@s">
                                    <button type="submit" class="btn btn-default" >{{ buttonTitle('upload') }}</button>
                                </div>  
                            </div>
                            <div class="res-msg"></div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="uk-width-expand@m user-data">
                <div class="uk-card-default rounded">
                    <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                        <h5 class="mb-0">{{ accountDetails }}</h5>
                        <!-- <a href="#" class="cog-menu"> <i class="icon-feather-settings"></i> <span class="tooltips">Change Password</span></a> -->
                    </div>
                    <hr class="m-0">
                    <div class="uk-child-width-1-2@s uk-grid-small p-4 uk-grid" >
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('name') }} </h6>
                            <p> {{ userName }} </p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('email') }} </h6>
                            <p> {{ userEmail }} </p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('phone') }} </h6>
                            <p> {{ userPhone }} </p>
                        </div>
                    </div>
                </div>
                <div class="uk-card-default rounded mt-4">
                    <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
                        <h5 class="mb-0">{{ address }} </h5>
                        <a href="#" @click.prevent="showAddressPopup = true" class="cog-menu"> 
                            <i title="Edit Address" class="icon-feather-settings"></i>
                            <span class="tooltips">{{ pageContent('update_address') }}</span>
                        </a>
                    </div>
                    <hr class="m-0">
                    
                    <div class="uk-child-width-1-2@s uk-grid-small p-4 uk-grid" >
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('address') }} </h6>
                            <p v-html="addressData.address"></p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('street') }} </h6>
                            <p>{{ addressData.street }}</p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('city') }} </h6>
                            <p>{{ addressData.city }}</p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('postal_code') }} </h6>
                            <p>{{ addressData.zip }} </p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('state') }} </h6>
                            <p>{{ addressData.state }}</p>
                        </div>
                        <div class="mb-3">
                            <h6 class="uk-text-bold"> {{ fields('country') }} </h6>
                            <p>{{ addressData.country }}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
        <transition name="modal" v-if="showAddressPopup">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <form method="POST" @submit.prevent="addressSubmit" autocomplete="off" >
                                <div class="modal-header">
                                    <h5 class="modal-title">{{ pageContent('edit_address') }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="closeAddressPopup">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="uk-child-width-1-2@s uk-grid-small p-4 uk-grid" >
                                        <div class="uk-form-group" id="address_block">
                                            <label class="uk-form-label"> {{ fields('address') }}</label>
                                            <div class="uk-position-relative w-100">
                                                <span class="uk-form-icon">
                                                    <i class="icon-line-awesome-map-marker"></i>
                                                </span>
                                                <input class="uk-input" maxlength="100" type="text" v-model.trim="$v.formData.address.$model" placeholder="Enter your address" value="" >
                                            </div>
                                            <p class="text-danger form-error" v-if="!formStatus && $v.formData.address.$error && !$v.formData.address.required">Please Enter your address!</p>
                                        </div>
                                        <div class="uk-form-group" id="street_block">
                                            <label class="uk-form-label"> {{ fields('street') }} </label>
                                            <div class="uk-position-relative w-100">
                                                <span class="uk-form-icon">
                                                    <i class="icon-line-awesome-street-view"></i>
                                                </span>
                                                <input class="uk-input" maxlength="100" type="text" v-model.trim="$v.formData.street.$model" placeholder="Enter your street" value="" >
                                            </div>
                                            <p class="text-danger form-error" v-if="!formStatus && $v.formData.street.$error && !$v.formData.street.required">Please Enter your street!</p>
                                        </div>
                                        <div class="uk-form-group" id="city_block">
                                            <label class="uk-form-label"> {{ fields('city') }} </label>
                                            <div class="uk-position-relative w-100">
                                                <span class="uk-form-icon">
                                                    <i class="icon-material-outline-location-city"></i>
                                                </span>
                                                <input class="uk-input" maxlength="100" type="text" v-model.trim="$v.formData.city.$model" placeholder="Enter your city" value="" >
                                            </div>
                                            <p class="text-danger form-error" v-if="!formStatus && $v.formData.city.$error && !$v.formData.city.required">Please Enter your city!</p>
                                        </div>
                                        <div class="uk-form-group" id="zip_block">
                                            <label class="uk-form-label"> {{ fields('postal_code') }} </label>
                                            <div class="uk-position-relative w-100">
                                                <span class="uk-form-icon">
                                                    <i class="icon-material-outline-my-location"></i>
                                                </span>
                                                <input class="uk-input" maxlength="20" type="tel" v-model.trim="$v.formData.zip.$model" placeholder="Enter your zip" value="" >
                                            </div>
                                            <p class="text-danger form-error" v-if="!formStatus && $v.formData.zip.$error && !$v.formData.zip.required">Please Enter your ZIP!</p>
                                            <p class="form-error text-danger" v-if="!formStatus && $v.formData.zip.$error && !$v.formData.zip.numeric">Invalid ZIP!</p>
                                        </div>
                                        <div class="uk-form-group" id="state_block">
                                            <label class="uk-form-label"> {{ fields('state') }} </label>
                                            <div class="uk-position-relative w-100">
                                                <span class="uk-form-icon">
                                                    <i class="icon-material-outline-location-on"></i>
                                                </span>
                                                <input class="uk-input" maxlength="100" type="text" v-model.trim="$v.formData.state.$model" placeholder="Enter your state" value="" >
                                            </div>
                                            <p class="text-danger form-error" v-if="!formStatus && $v.formData.state.$error && !$v.formData.state.required">Please Enter your state!</p>
                                        </div>
                                        <div class="uk-form-group" id="country_block">
                                            <label class="uk-form-label"> {{ fields('country') }} </label>
                                            <div class="uk-position-relative w-100">
                                                <span class="uk-form-icon">
                                                    <i class="icon-feather-globe"></i>
                                                </span>
                                                <select class="uk-input" v-model.trim="$v.formData.country.$model">
                                                    <option value="">Choose Country</option>
                                                    <option 
                                                        v-for="(data) of countryJson" 
                                                        :key="data.isoCode"
                                                    >{{ data.name }}</option>
                                                </select>
                                            </div>
                                            <p class="text-danger form-error" v-if="!formStatus && $v.formData.country.$error && !$v.formData.country.required">Please Enter your country!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default-outline" @click="closeAddressPopup">{{ buttonTitle('close') }}</button>
                                    <button type="submit" class="btn btn-default">{{ buttonTitle('save_changes') }}</button>
                                    <div class="resMssg text-center mt-3 col-sm-12" v-html="formResponse"></div> 
                                </div>    
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
//import AddressPopup from './AddressPopup'
import Avatar from '../Students/Avatar'
import CountryData from '../../assets/countries_code.json'
import { required, numeric } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    components: {Avatar},
    validations: {
        formData: {
            address: {required},
            city: {required},
            state: {required},
            country:{required},
            zip: {required, numeric},
            street: {required}
        }
    },
    data() {
        return {
            showAddressPopup: false,
            formData: {
                address: '',
                city:'',
                state:'',
                country:'',
                zip:'',
                street: ''
            },
            countryJson: CountryData,
            formResponse: '',
            formStatus: true,
            addressData: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en',
        }
    },
    created: function(){
        this.getAddress();
    },
    computed: {
        pageTitle(){
            return Translation.pagetitle[this.defaultLang].account_settings
        },
        accountDetails(){
            return Translation.pagetitle[this.defaultLang].account_details
        },
        address(){
            return Translation.pagetitle[this.defaultLang].address
        },
        userName() {
            return this.$store.state.auth.name
        },
        userEmail() {
            return this.$store.state.auth.email
        },
        userPhone() {
            return this.$store.state.auth.phone
        },
        memberSince() {
            return this.$store.state.auth.member_since
        },
        userAvatar() {
            return this.$store.state.auth.avatar
        }
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        buttonTitle(field){
            return Translation.button[this.defaultLang][field]
        },
        fields(field){
            return Translation.fields[this.defaultLang][field]
        },
        closeAddressPopup(){
            this.showAddressPopup = false
            this.formStatus = true
        },
        getAddress() {
            this.$store.dispatch('userAddress').then(res=>{
                this.addressData = res
                Object.keys(this.formData).forEach(item => {
                    this.formData[item] = this.addressData[item]
                })
            })
            .catch(error => console.log(error))
        },
        addressSubmit(){
            //this.formStatus = false
            //this.$v.$touch()
            //if(!this.$v.$invalid){
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                this.$http.post(`/account/user/update-address`, this.formData )
                .then(res=>{
                    if(typeof res !== 'undefined' && res.status === 200){
                         if(res.data.status === true){
                            this.resetForm()
                            this.formResponse =`<p class="alert alert-success">${res.data.message}</p>`   
                            setTimeout(()=>{
                                this.getAddress();
                                this.showAddressPopup = false 
                            }, 1000)
                         }                
                        else
                            this.formResponse =`<p class="alert alert-danger">${res.data.error}</p>`
                    }
                    
                }).catch(err => {
                    console.log('err', err);
                    this.formResponse =`<p class="alert alert-danger">Something Went Wrong!</p>`
                })

                this.togellingResponse();
            //}
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },        
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })    
            this.formStatus = true
        },
        uploadDP() {
            let formData = new FormData();
            formData.append('dp', this.$refs.file.files[0]);

            this.$store.dispatch('userAvatar', formData).then(function(res){
                if(res.status === true) {
                    document.querySelector('#update_profile_picture').reset()
                }
            })
            .catch(function(err){
                console.log(err);
            });
        },
        deleteAvatar() {
            this.$store.dispatch(`removeAvatar`)
        }
    },
    watch:{
        "$store.state.auth.avatar"() {
            this.userAvatar
        }
    }
}
</script>

<style scoped>
    .user-data h6{margin-bottom: 0;}
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-dialog {
        max-width: 700px;
    }
    
</style>
